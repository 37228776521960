import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Col, Form, Row, Button, Dropdown } from 'react-bootstrap';
import { getStoredSearch } from '../utils/Search.jsx';
import { DatePicker } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import leftright_img from '../images/leftright_img.svg';
import airoplaneIcon from '../images/plane-svgrepo-com.svg';
import placeIcon from '../images/pin-svgrepo-com.svg';
import searchIcon from '../images/searchIcon.svg';
// Hours 
const options = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
];
const ResultSearch = ({ cars, setTabIndex, setCars, setLoading, locationDetails, setLocationDetails, setTimezone }) => {
    dayjs.extend(isSameOrBefore);
    const [filteredResults, setFilteredResults] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showDropOffSuggestions, setShowDropOffSuggestions] = useState(false);
    const pickupRef = useRef(null);
    const dropoffRef = useRef(null);
    const [showHoursSuggestions, setShowHoursSuggestions] = useState(false);
    const storedSearch = getStoredSearch();
    const [bookingtype, setBookingType] = useState(storedSearch?.booking_type ?? 'p_to_p');
    const [pickupPoint, setPickupPoint] = useState(storedSearch?.pickup ?? '');
    const [dropOffPoint, setDropOffPoint] = useState(storedSearch?.dropoff ?? '');
    const [pickupType, setPickupType] = useState(storedSearch?.pickup_type ?? '');
    const [dropoffType, setDropoffType] = useState(storedSearch?.dropoff_tpye ?? '');
    const [departureDate, setDepartureDate] = useState(storedSearch?.date === 'null' ? null : storedSearch?.date);
    const [pickupTimestamp, setPickupTimestamp] = useState(storedSearch?.pickup_timestamp ?? null);
    const [pickupTime, setPickupTime] = useState(storedSearch?.time ?? null);
    const [returnDate, setReturnDate] = useState(storedSearch?.return_date === 'null' ? null : storedSearch?.return_date);
    const [returnTimestamp, setReturnTimestamp] = useState(storedSearch?.return_timestamp ?? null);
    const [returnPickUpTime, setReturnPickUpTime] = useState(storedSearch?.return_pickup_time ?? null);
    const [passengers, setPassengers] = useState(storedSearch?.pass ?? 1);
    const [bags, setBags] = useState(storedSearch?.bags ?? 1);
    const [pickupCoordinates, setpickupCoordinates] = useState({ lat: storedSearch?.pickup_lat, lng: storedSearch?.pickup_lng });
    const [dropoffCoordinates, setdropoffCoordinates] = useState({ lat: storedSearch?.drop_lat, lng: storedSearch?.drop_lng });
    const [selectedduration, setSelectedDuration] = useState(storedSearch?.timebased ?? null);
    const fetchCars = async (reqObj) => {
        setLoading(true);
        const {
            num_passenger,
            num_bags,
            pickup_date,
            return_date,
            return_pickup_time,
            currency,
            pickup_time,
            pickup,
            destination,
            booking_type,
            duration,
        } = reqObj;
        let durationValue = duration ?? 1;
        let subqueryparam =
            booking_type === "city_tour" || booking_type === "hourly"
                ? `&perhour=on&timebased=${durationValue}`
                : `&destination=${destination.lat},${destination.lng}`;
        let retuenqueryparam =
            (return_date === "" || return_date === null || return_date === "null")
                ? ``
                : (booking_type === "p_to_p" || booking_type === "intercity") ? `&return_pickup_date=${return_date}&return_pickup_time=${return_pickup_time}` : ``;
        const configs = {
            method: "GET",
            url: `${config.apiSearch}?num_passenger=${num_passenger}&num_bags=${num_bags}&pickup_date=${pickup_date}&pickup_time=${pickup_time}&pickup=${pickup.lat},${pickup.lng}&currency=${currency}${subqueryparam}${retuenqueryparam}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585',
            }
        };
        const bookingResponse = await axios(configs).catch((e) => { });
        if (bookingResponse?.data?.data?.booking_list?.[0]) {
            const distance = bookingResponse.data.data.booking_list[0].distance;
            const duration = bookingResponse.data.data.booking_list[0].duration;
            setLocationDetails((locationDetails) => {
                return { ...locationDetails, distance, duration };
            });
            setCars(bookingResponse.data.data.booking_list);
        }
        setLoading(false);
    };
    const bookingsearch = () => {
        setCars([]);
        setLocationDetails({
            pickupDate: departureDate || null,
            pickupTime: pickupTime || null,
            pickupLocation: pickupPoint,
            dropoffLocation: dropOffPoint,
            passengers: passengers || 1,
            bags: bags || 1,
            pickupGeometry: { lat: pickupCoordinates.lat, lng: pickupCoordinates.lng } || {},
            dropoffGeometry: { lat: dropoffCoordinates.lat, lng: dropoffCoordinates.lng } || {},
            booking_type: bookingtype,
            booking_duration: selectedduration,
        });
        fetchCars({
            currency: localStorage.getItem("currencyValue") || "EUR",
            num_passenger: passengers,
            num_bags: bags,
            pickup_time: pickupTime || null,
            pickup_date: departureDate ? dayjs(departureDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD') : null,
            return_pickup_time: returnPickUpTime || null,
            return_date: returnDate ? dayjs(returnDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD') : null,
            pickup: { lat: pickupCoordinates.lat, lng: pickupCoordinates.lng },
            destination: { lat: dropoffCoordinates.lat, lng: dropoffCoordinates.lng },
            booking_type: bookingtype,
            duration: selectedduration,
        });
    };
    useEffect(() => {
        setLoading(true);
        bookingsearch();
        window.scrollTo(0, 0);
        const fetchTimezone = async (lat, lng) => {
            try {
                const response = await axios.get(
                    `https://api.ipgeolocation.io/timezone?apiKey=14b0d737e7da46cf920641394a68a4e7&lat=${lat}&long=${lng}`
                );
                setTimezone(response.data.timezone);
            } catch (err) {
            }
        };
        if (storedSearch) {
            fetchTimezone(storedSearch.pickup_lat, storedSearch.pickup_lng);
        }

    }, []);
    const fetchPlaceDetailsPickup = (placeId) => {
        const map = new window.google.maps.Map(document.createElement('div'));
        const service = new window.google.maps.places.PlacesService(map);

        const request = {
            placeId: placeId,
            fields: ['name', 'formatted_address', 'geometry'],
        };

        service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                // console.log('Place details:', place);
                setpickupCoordinates({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                });
            } else {
                console.error('Failed to get place details:', status);
            }
        });
    };
    const fetchPlaceDetailsDropoff = (placeId) => {
        const map = new window.google.maps.Map(document.createElement('div'));
        const service = new window.google.maps.places.PlacesService(map);

        const request = {
            placeId: placeId,
            fields: ['name', 'formatted_address', 'geometry'],
        };

        service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                // console.log('Place details:', place);
                setdropoffCoordinates({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                });
            } else {
                console.error('Failed to get place details:', status);
            }
        });
    };

    const handlePickUpChange = async (e) => {
        const value = e.target.value;
        setPickupPoint(value);

        if (value && value.length > 2) {
            const url = `https://api.airporttransfer.com/api/booker_autocomplete?search=${value}`;
            try {
                const response = await axios.post(url);
                const fetchedResults = response.data.airports.map((item, index) => ({
                    id: index,
                    name: item.name,
                    place: item.airport_place_id,
                    type: 'airport',
                    coordinates: item.coordinates,
                }));
                const fetchedResultss = response.data.places.map((item, index) => ({
                    id: index,
                    name: item.description,
                    place: item.place_id,
                    type: 'place',
                    coordinates : null
                }));
                const mergedResults = [...fetchedResults, ...fetchedResultss];
                // console.log(mergedResults);
                setFilteredResults(mergedResults);
                setShowSuggestions(true)
            } catch (error) {
                console.error('Error fetching data:', error);
                setFilteredResults([]);
            }
        } else {
            setShowSuggestions(false);
            setFilteredResults([]);
        }
    };
    const handlePickUpClick = (option) => {
        if(option.place){
            fetchPlaceDetailsPickup(option.place);
        }else{
            setpickupCoordinates({
                lat: option?.coordinates?.latitude ?? '',
                lng: option?.coordinates?.longitude ?? ''
            });           
        }
        setPickupPoint(option.name);
        setPickupType(option.type);
        setShowSuggestions(false);
    };
    const handleDropOffChange = async (e) => {
        const value = e.target.value;
        setDropOffPoint(value);

        if (value && value.length > 2) {
            const url = `https://api.airporttransfer.com/api/booker_autocomplete?search=${value}`;
            try {
                const response = await axios.post(url);
                const fetchedResults = response.data.airports.map((item, index) => ({
                    id: index,
                    name: item.name,
                    place: item.airport_place_id,
                    type: 'airport',
                    coordinates: item.coordinates
                }));
                const fetchedResultss = response.data.places.map((item, index) => ({
                    id: index,
                    name: item.description,
                    place: item.place_id,
                    type: 'place'
                }));
                const mergedResults = [...fetchedResults, ...fetchedResultss];
                // console.log(mergedResults);
                setFilteredResults(mergedResults);
                setShowDropOffSuggestions(true)
            } catch (error) {
                console.error('Error fetching data:', error);
                setFilteredResults([]);
            }
        } else {
            setShowDropOffSuggestions(false);
            setFilteredResults([]);
        }
    };


    const handleDropOffClick = (option) => {
        console.log(option)
        if(option.place){
            fetchPlaceDetailsDropoff(option.place);
        }else{
            setdropoffCoordinates({
                lat: option?.coordinates?.latitude ?? '',
                lng: option?.coordinates?.longitude ?? ''
            });           
        }
        setDropOffPoint(option.name);
        setDropoffType(option.type);
        setShowDropOffSuggestions(false);
    };
    const handlePassengersChange = (increment) => {
        const total = Number(passengers) + Number(increment);
        if (total < 0) {
            setPassengers(0);
        } else {
            setPassengers(total);
        }

    };

    const handleBagsChange = (increment) => {
        const total = Number(bags) + Number(increment);
        if (total < 0) {
            setBags(0);
        } else {
            setBags(total);
        }
    };
    const handleHoursChange = (e) => {
        const value = e.target.value;
        setSelectedDuration(value);
        setShowHoursSuggestions(true);
    };
    const handleHoursClick = (option) => {
        setSelectedDuration(option.value);
        setShowHoursSuggestions(false);
    };
    const disablePastDates = (current) => {
        // Can not select days before today
        return current && current < dayjs().startOf('day');
    };
    const handleDateChange = (value, dateString) => {
        if (value) {
            const Timestamppp = value.valueOf();
            setPickupTimestamp(Timestamppp);
            const hours = String(value?.$H).padStart(2, '0');
            const minutes = String(value?.$m).padStart(2, '0');
            setPickupTime(`${hours}:${minutes}`);
            setDepartureDate(dateString);
        } else {
            setPickupTime(null);
            setDepartureDate(null);
        }
    };
    const handleReturnDateChange = (value, dateString) => {
        if (value) {
            const returnTimestamppp = value.valueOf();
            setReturnTimestamp(returnTimestamppp);
            if(departureDate === null || departureDate === undefined){
              toast.error('Please select departure date first.');
              setReturnDate(null);
              return;
            }
            if (returnTimestamppp <= pickupTimestamp) {
              setReturnDate(null);
              toast.error('Return date must be greater than departure date');
              return;
            }
            const hours = String(value?.$H).padStart(2, '0');
            const minutes = String(value?.$m).padStart(2, '0');
            setReturnPickUpTime(`${hours}:${minutes}`);
            setReturnDate(dateString);
        } else {
            setReturnPickUpTime(null);
            setReturnDate(null);
        }
    };
    const renderIcon = (itemClass) => {
        switch (itemClass) {
            case 'Airport':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" width="1.5rem" height="1.5rem" class="BpkIcon_bpk-icon--rtl-support__OTJmO">
                        <path d="M19.616 3.047a6 6 0 0 0-2.536 1.547l-8.188 8.373a3.4 3.4 0 0 0-.403.498l-1.287 1.186a.9.9 0 0 1-.594.24l-2.58.05a.91.91 0 0 0-.976.616.943.943 0 0 0 .473 1.155l2.56 1.012a.9.9 0 0 1 .492.468l1.038 2.27a.905.905 0 0 0 1.16.474.934.934 0 0 0 .575-.987l-.114-2.36a.92.92 0 0 1 .282-.709l1.173-1.12a3.4 3.4 0 0 0 .54-.448l1.27-1.298.609.621 2.886 6.062a.452.452 0 0 0 .732.123l.504-.516a1.88 1.88 0 0 0 .487-1.728l-1.582-7.085a1.84 1.84 0 0 1 .478-1.685l2.887-2.952a6.15 6.15 0 0 0 1.41-2.28l.022-.059a1.112 1.112 0 0 0-1.318-1.469z"></path>
                        <path d="M12.03 8.22a.458.458 0 0 0-.257-.765L5.517 6.068a1.79 1.79 0 0 0-1.69.498l-.52.53a.46.46 0 0 0 .12.73L9.073 10.9c.358.18.56-.104.833-.399z"></path>
                    </svg>
                );
            case 'City':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" width="1.5rem" height="1.5rem" class="BpkIcon_bpk-icon--rtl-support__OTJmO"><path d="M19 6h-4a4 4 0 0 0-4-4H7a4 4 0 0 0-4 4v15a1 1 0 0 0 2 0V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2 2.15 2.15 0 0 0-2 2v13a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V21a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a2.15 2.15 0 0 0-2-2m-5 11a1 1 0 1 1 1-1 1 1 0 0 1-1 1m0-3a1 1 0 1 1 1-1 1 1 0 0 1-1 1m0-3a1 1 0 1 1 1-1 1 1 0 0 1-1 1m4 6a1 1 0 1 1 1-1 1 1 0 0 1-1 1m0-3a1 1 0 1 1 1-1 1 1 0 0 1-1 1m0-3a1 1 0 1 1 1-1 1 1 0 0 1-1 1M9 7a1 1 0 1 1-1-1 1 1 0 0 1 1 1m0 3a1 1 0 1 1-1-1 1 1 0 0 1 1 1m0 6a1 1 0 1 1-1-1 1 1 0 0 1 1 1m0-3a1 1 0 1 1-1-1 1 1 0 0 1 1 1m0 6a1 1 0 1 1-1-1 1 1 0 0 1 1 1"></path></svg>
                );
            case 'Area':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" width="1.5rem" height="1.5rem" class="BpkIcon_bpk-icon--rtl-support__OTJmO"><path d="M12.001 1a6.001 6.001 0 0 1 2.822 11.297c-.768.41-1.767.833-1.822 1.703v8a1.023 1.023 0 0 1-.969 1 1.08 1.08 0 0 1-1.031-1v-8c-.058-.87-1.03-1.285-1.8-1.693A6 6 0 0 1 12.001 1"></path></svg>
                );
            case 'District':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" width="1.5rem" height="1.5rem" class="BpkIcon_bpk-icon--rtl-support__OTJmO"><path d="M12.001 1a6.001 6.001 0 0 1 2.822 11.297c-.768.41-1.767.833-1.822 1.703v8a1.023 1.023 0 0 1-.969 1 1.08 1.08 0 0 1-1.031-1v-8c-.058-.87-1.03-1.285-1.8-1.693A6 6 0 0 1 12.001 1"></path></svg>
                );
            default:
                return null;
        }
    };
    const validateInputs = () => {
        if (bookingtype === 'p_to_p') {
            return pickupPoint && dropOffPoint && departureDate && pickupTime && passengers && bags;
        } else if (bookingtype === 'intercity') {
            return pickupPoint && dropOffPoint && departureDate && pickupTime && passengers && bags;
        } else if (bookingtype === 'city_tour') {
            return pickupPoint && selectedduration && departureDate && pickupTime && passengers && bags;
        } else if (bookingtype === 'hourly') {
            return pickupPoint && selectedduration && departureDate && pickupTime && passengers && bags;
        }
    };
    const modifySearch = () => {
        const queryParams = new URLSearchParams(window.location.search);
        localStorage.removeItem("booking_id");
        const params = new URLSearchParams();
        params.set("date", departureDate ?? null);
        params.set("return_timestamp", returnTimestamp ?? null);
        params.set("pickup_timestamp", pickupTimestamp ?? null);
        params.set("return_date", returnDate ?? null);
        params.set("return_pickup_time", returnPickUpTime ?? null);
        params.set("time", pickupTime ?? null);
        params.set("pickup", pickupPoint);
        params.set("pickup_lat", pickupCoordinates.lat);
        params.set("pickup_lng", pickupCoordinates.lng);
        params.set("drop_lat", dropoffCoordinates.lat);
        params.set("drop_lng", dropoffCoordinates.lng);
        params.set("dropoff", dropOffPoint);
        params.set("pass", passengers);
        params.set("bags", bags);
        params.set("pickup_type", pickupType);
        params.set("dropoff_type", dropoffType);
        if (bookingtype === 'hourly' || bookingtype === 'city_tour') {
            params.set("perhour", 'on');
            params.set("timebased", selectedduration);
        }
        params.set("booking_type", bookingtype);

        const paramsObject = {};
        for (const [key, value] of params) {
            paramsObject[key] = value;
        }
        // Step 2: Store Object in localStorage
        localStorage.setItem('search_param', JSON.stringify(paramsObject));
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState({}, "", newUrl);
        setCars([]);
        setLoading(true);
        setLocationDetails({
            pickupDate: departureDate || null,
            pickupTime: pickupTime || null,
            pickupLocation: pickupPoint,
            dropoffLocation: dropOffPoint,
            passengers: passengers || 0,
            bags: bags || 0,
            pickupGeometry: { lat: pickupCoordinates.lat, lng: pickupCoordinates.lng } || {},
            dropoffGeometry: { lat: dropoffCoordinates.lat, lng: dropoffCoordinates.lng } || {},
            booking_type: bookingtype,
            booking_duration: selectedduration,
        });
        fetchCars({
            currency: localStorage.getItem("currencyValue") || "EUR",
            num_passenger: passengers,
            num_bags: bags,
            pickup_time: pickupTime || null,
            pickup_date: departureDate ? dayjs(departureDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD') : null,
            return_pickup_time: returnPickUpTime || null,
            return_date: returnDate ? dayjs(returnDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD') : null,
            pickup: { lat: pickupCoordinates.lat, lng: pickupCoordinates.lng },
            destination: { lat: dropoffCoordinates.lat, lng: dropoffCoordinates.lng },
            booking_type: bookingtype,
            duration: selectedduration,
        });
        setTabIndex(1)
    };
    const handleSwitch = () => {
        setDropOffPoint(pickupPoint);
        setdropoffCoordinates({
            lat: pickupCoordinates.lat,
            lng: pickupCoordinates.lng
        });
        setPickupPoint(dropOffPoint);
        setpickupCoordinates({
            lat: dropoffCoordinates.lat,
            lng: dropoffCoordinates.lng
        });
    }
    const handleFocus = () => {
        pickupRef.current.select();
      };
      const handleFocusD = () => {
        dropoffRef.current.select();
      };
    return (
        <>
            <Col md={'11'} className='col-11 text-center mx-auto tabs_wrap_result'>
                <Form>
                    <ul className='list-unstyled position-relative'>
                        <li className='aye-hay-oye-hoye'>
                            <Row className='align-items-center d-lg-flex d-block row-cols-3 gx-3'>
                                <Col xxl={'6'} className='col-12 mb-3 mb-xxl-0'>
                                    <Row className='text-start position-relative'>
                                        <Col md={'6'} sm={'12'} className='mb-3 mb-md-0'>
                                            <div className='text-start autosuggest w-100 justify-content-between align-items-center whiteBG px-3 py-1' style={{ borderRadius: '10px' }}>
                                                <Form.Label className='mb-0'>PICKUP POINT</Form.Label>
                                                <Form.Control ref={pickupRef}  onFocus={handleFocus} type="text" value={pickupPoint} onChange={handlePickUpChange} placeholder='Airport, address, place or hotel name...' />
                                                {showSuggestions && (
                                                    <ul className="suggestions-list">
                                                        {filteredResults.map((option, index) => (
                                                            <section className='suggestions-li' key={index} onClick={() => handlePickUpClick(option)} style={{ display: "flex", alignItems: 'center', padding: '6px', borderRadius: '6px', cursor:'pointer' }}>
                                                                <div style={{ fill: '#626971', display: 'flex', marginRight: '.5rem' }}>
                                                                    <img
                                                                        src={option.type === 'airport' ? airoplaneIcon : placeIcon}
                                                                        alt={option.type}
                                                                        style={{ width: '25px', height: '25px' }}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '100%' }}>
                                                                    <div style={{ display: "block" }}>
                                                                        <span>
                                                                            <span style={{ fontWeight: '700' }}>{option.name}</span>
                                                                        </span><br />
                                                                        <small>{option.hierarchy}</small>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </Col>
                                        {(bookingtype === 'intercity' || bookingtype === 'p_to_p') &&
                                            <Button onClick={() => handleSwitch()} variant={''} className='exchangerbtn p-0 w-100 position-absolute start-0 end-0 mx-auto' style={{ maxWidth: '35px', zIndex: '1', minWidth: '35px' }}><img className='img-fluid' src={leftright_img} alt='leftright_img' /></Button>
                                        }
                                        {(bookingtype === 'hourly' || bookingtype === 'city_tour') &&
                                            <Col md={'6'} sm={'12'}>
                                                <div className='text-start autosuggest w-100 justify-content-between align-items-center whiteBG px-3 py-1' style={{ borderRadius: '10px' }}>
                                                    <Form.Label className='mb-0'>DURATION (in Hour)</Form.Label>
                                                    <Form.Control type="text" readOnly value={selectedduration} onFocus={handleHoursChange} onChange={handleHoursChange} className="border-0" />
                                                    {showHoursSuggestions && (<ul className="suggestions-list" style={{ top: '57px', left: '-1px', width: '269px' }}>
                                                        {options.map((option, index) => (
                                                            <section key={index} onClick={() => handleHoursClick(option)} style={{ display: "flex" }}>
                                                                <div style={{ width: '100%' }}>
                                                                    <div style={{ display: "block" }}>
                                                                        <span>
                                                                            <span style={{ fontWeight: '700' }}>{option.value}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        ))}
                                                    </ul>
                                                    )}
                                                </div>
                                            </Col>
                                        }
                                        {(bookingtype === 'intercity' || bookingtype === 'p_to_p') &&
                                            <Col md={'6'} sm={'12'}>
                                                <div className='text-start autosuggest w-100 justify-content-between align-items-center whiteBG px-3 py-1' style={{ borderRadius: '10px' }}>
                                                    <Form.Label className='mb-0'>DROP-OFF POINT</Form.Label>
                                                    <Form.Control ref={dropoffRef}  onFocus={handleFocusD} type="text" value={dropOffPoint} onChange={handleDropOffChange} placeholder='Airport, address, place or hotel name...' />
                                                    {showDropOffSuggestions && (
                                                        <ul className="suggestions-list">
                                                            {filteredResults.map((option, index) => (
                                                                <section className='suggestions-li' key={index} onClick={() => handleDropOffClick(option)} style={{ display: "flex", alignItems: 'center', padding: '6px', borderRadius: '6px', cursor:'pointer' }}>
                                                                    <div style={{ fill: '#626971', display: 'flex', marginRight: '.5rem' }}>
                                                                        <img
                                                                            src={option.type === 'airport' ? airoplaneIcon : placeIcon}
                                                                            alt={option.type}
                                                                            style={{ width: '25px', height: '25px' }}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: '100%' }}>
                                                                        <div style={{ display: "block" }}>
                                                                            <span>
                                                                                <span style={{ fontWeight: '700' }}>{option.name}</span>
                                                                            </span><br />
                                                                            <small>{option.hierarchy}</small>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col xxl={'5'} className='col-12'>
                                    <Row className='text-start gx-3'>
                                        <Col xl={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 4 : 6} lg={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 12 : 12} sm={'12'} className='mb-3 mb-xxl-0'>
                                            <div className='w-100 whiteBG px-2' style={{ borderRadius: '10px', height: "56px" }}>
                                                <Form.Label className='mb-0'>DEPARTURE DATE/TIME</Form.Label><br />
                                                <DatePicker
                                                    showTime
                                                    disabledDate={disablePastDates}
                                                    defaultValue={departureDate ? dayjs(departureDate, 'DD-MM-YYYY HH:mm') : null}
                                                    onChange={handleDateChange}
                                                    onOk={handleDateChange}
                                                    format="DD-MM-YYYY HH:mm"
                                                    minuteStep={5}
                                                />
                                            </div>
                                        </Col>
                                        {(bookingtype === 'intercity' || bookingtype === 'p_to_p') &&
                                            <Col xl={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 4 : 6} lg={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 6 : 6} sm={'12'} className='mb-3 mb-xxl-0'>
                                                {returnDate ?
                                                    <div className='w-100 whiteBG px-2' style={{ borderRadius: '10px', height: "56px" }}>
                                                        <Form.Label className='mb-0'>RETURNS DATE/TIME</Form.Label>
                                                        <DatePicker
                                                            showTime
                                                            disabledDate={disablePastDates}
                                                            defaultValue={returnDate ? dayjs(returnDate, 'DD-MM-YYYY HH:mm') : null}
                                                            onChange={handleReturnDateChange}
                                                            onOk={handleReturnDateChange}
                                                            format="DD-MM-YYYY HH:mm"
                                                            minuteStep={5}
                                                        />
                                                    </div>
                                                    :
                                                    <div className='w-100 passengerDropdown' style={{ borderRadius: '10px', height: "56px" }}>
                                                        <Dropdown className="d-inline dropend dropenddropend">
                                                            <Dropdown.Toggle variant={''} style={{ borderRadius: '8px', maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%', }} className={'add-return1-btn whiteText'} id="dropdown-autoclose-true">Add Return</Dropdown.Toggle>
                                                            <Dropdown.Menu className='view p-2'>
                                                                <Form.Label className='mb-0'>RETURNS DATE/TIME</Form.Label><br />
                                                                <DatePicker
                                                                    defaultValue={returnDate ? dayjs(returnDate, 'DD-MM-YYYY HH:mm') : null}
                                                                    showTime
                                                                    disabledDate={disablePastDates}
                                                                    onChange={handleReturnDateChange}
                                                                    onOk={handleReturnDateChange}
                                                                    format="DD-MM-YYYY HH:mm"
                                                                    minuteStep={5}
                                                                />
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                }
                                            </Col>
                                        }
                                        <Col xl={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 4 : 6} lg={bookingtype === 'intercity' || bookingtype === 'p_to_p' ? 6 : 6} sm={'12'} className='mb-3 mb-xxl-0'>
                                            <div className='w-100 whiteBG px-2 py-1 ' style={{ borderRadius: '10px', height: "56px" }}>
                                                <Form.Label className='mb-0 d-block'>PASSENGERS / BAGS</Form.Label>
                                                <Dropdown className="passengerDropdown">
                                                    <Dropdown.Toggle variant={''} className={'secondaryText border-0 ps-0 py-0 pe-0 d-block w-100 text-start'} style={{ fontSize: '14px', }} id="dropdown-autoclose-true">{passengers} Adult{passengers > 1 ? 's' : ''} / {bags} Bag{bags > 1 ? 's' : ''}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='view p-3' style={{ width: '210px' }}>
                                                        <div className='d-flex  justify-content-between mb-2' style={{ gap: "10px" }}>
                                                            <span>Passengers</span>
                                                            <div>
                                                                <Button variant={''} type='button' className="color2BG" style={{ borderRadius: '100px', padding: '0', width: '25px', borderColor: '#727B92' }} onClick={() => handlePassengersChange(-1)}>-</Button>
                                                                <span className="mx-2">{passengers}</span>
                                                                <Button variant={''} type='button' className="mainBG blackText" style={{ borderRadius: '100px', borderColor: '#FAE069', padding: '0', width: '25px' }} onClick={() => handlePassengersChange(1)}>+</Button>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex justify-content-between' style={{ gap: "10px" }}>
                                                            <span>Bags</span>
                                                            <div>
                                                                <Button variant={''} type='button' className="color2BG" style={{ borderRadius: '100px', padding: '0', width: '25px', borderColor: '#727B92' }} onClick={() => handleBagsChange(-1)}>-</Button>
                                                                <span className="mx-2">{bags}</span>
                                                                <Button variant={''} type='button' className="mainBG blackText" style={{ borderRadius: '100px', borderColor: '#FAE069', padding: '0', width: '25px' }} onClick={() => handleBagsChange(1)}>+</Button>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={'1'}>
                                    <div className='w-100 mb-3 mb-sm-0' style={{ borderRadius: '10px', height: "56px" }}>
                                        <Button onClick={modifySearch} style={{ borderRadius: '8px', maxWidth: '100%', minWidth: '100%', minHeight: '100%', maxHeight: '100%', }} className='d-flex btn add-return-btn align-items-center py-0'><img className='img-fluid mx-auto' src={searchIcon} alt='icon' /></Button>
                                    </div>
                                </Col>
                            </Row>
                        </li>
                    </ul>
                </Form>
            </Col>
        </>
    );
};

export default ResultSearch;