import React, { useEffect } from 'react';
import { Col, Card, Form, Button, Row, CardBody, CardHeader } from "react-bootstrap";
import { Link } from 'react-router-dom';
// import images
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import imageAgency_01 from "../images/imageAgency_01.png";
import firstimage from "../images/Rectangle 202.png";
import twoimage from "../images/Rectangle 2022.png";
import thirdimage from "../images/Rectangle 2021.png";
import userIcon from '../images/userIcon1.svg';
import breifIcon from '../images/breifIcon.svg';
import timeIcon from '../images/timeIcon.svg';
import meetgreetIcon from '../images/meetgreetIcon.svg';
import carimage from "../images/carIMG.png";
import slide from "../images/slide.png";
import checkicon from '../images/checkicon.svg';
import mapimage from '../images/mapimage.png';
import ResultSearch from '../components/ResultSearch.jsx';
// import required modules
import { Pagination } from 'swiper/modules';
// optionsprojects
const optionsprojects = {
    spaceBetween: 30,
    modules: [Autoplay],
    breakpoints: {
      400: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 3,
      },
      1000: {
        slidesPerView: 4,
      },
      1300: {
        slidesPerView: 4,
      },
      1500: {
        slidesPerView: 5,
      },
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    loop: true,
  };
const CitiTourDetail = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <section className='py-4 color1BG resultPageTopRow' style={{ position: 'relative' }}>
                Search
            </section>
            {/*  */}
            <section className=' color1BG'>
                <Col xxl={'10'} xl={'10'} className='col-11 mx-auto'>
                <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={30}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
        <SwiperSlide><img src={slide} alt="slide1" /></SwiperSlide>
      </Swiper>
                </Col>
            </section>
            <section className=' color1BG'> 
                <Col xxl={'10'} xl={'10'} className='col-11 mx-auto'>
                    <Row className='align-items-start vehicleUl'>
                        <Col xxl={'8'} xl={'8'} className={'mb-4 mb-xl-0 info_wrap'}>
                            <div className='mb-4 pb-4 mb-lg-5 pb-lg-5'>
                                <h2 className='mb-4'>Epic Rome: A Journey Through the Eternal City</h2>
                                <p>Bypass the standard group sightseeing tours and experience Bergen in your own unique manner with this flexible, personalized trip. Select the luxury vehicle you wish to use for the day, then drive to the sites you want to see. Private excursion just for your group: provides a customized experience. Tour can be completely tailored to your interests. For the duration of the tour, you and your group can use the car. Complimentary hassle-free hotel or airport pickup and drop-off. Tour with tickets and without tickets options.</p>
                                <b>Map Itinerary</b>
                                <img src={mapimage} className='mb-3' width={'100%'} alt="test" />
                                <div className='contentCityTour'>
                                    <div className='d-flex align-items-start position-relative mb-5'>
                                        <h4 className='text-center titleNumber'>01</h4>
                                        <div className='ms-lg-4 ms-3'>
                                            <h5 className='mb-3 mt-3 title'>COLOSSEUM (12:00 - 12:45)</h5>
                                            <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Begin your tour at the iconic Colosseum, Rome's grand amphitheater known for its gladiatorial games. Explore both the exterior and interior to appreciate its vast scale and historical significance. Take some time to capture photos and listen to a brief overview of its history.</p>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className='d-flex align-items-start position-relative mb-5'>
                                        <h4 className='text-center titleNumber'>02</h4>
                                        <div className='ms-lg-4 ms-3'>
                                            <h5 className='mb-3 mt-3 title'>Roman Forum (12:45 - 1:30)</h5>
                                            <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Walk to the nearby Roman Forum, the heart of ancient Rome’s public life. Wander through the ruins of temples, basilicas, and government buildings, and learn about the bustling center of Roman political, social, and economic activities. Enjoy the panoramic views of the surrounding archaeological sites.</p>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className='d-flex align-items-start position-relative mb-5'>
                                        <h4 className='text-center titleNumber'>03</h4>
                                        <div className='ms-lg-4 ms-3'>
                                            <h5 className='mb-3 mt-3 title'>Pantheon (1:30 - 2:45)</h5>
                                            <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Proceed to the Pantheon, a remarkably preserved temple dedicated to all Roman gods. Marvel at its impressive dome and oculus, which floods the interior with natural light. The Pantheon’s architectural marvel and its serene atmosphere provide a fascinating contrast to the bustling Forum and Colosseum.
                                                Ended with a 30 minute lunch break.</p>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className='d-flex align-items-start position-relative mb-5'>
                                        <h4 className='text-center titleNumber'>04</h4>
                                        <div className='ms-lg-4 ms-3'>
                                            <h5 className='mb-3 mt-3 title'>Trevi Fountain (2:45 - 3:15)</h5>
                                            <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Head to the Trevi Fountain, renowned for its elaborate Baroque design and the tradition of coin-throwing to ensure a return to Rome. Admire the intricate sculptures and the flowing water, and take in the lively, bustling square surrounding the fountain.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-start position-relative mb-5 mb-lg-0'>
                                        <h4 className='text-center titleNumber'>05</h4>
                                        <div className='ms-lg-4 ms-3'>
                                            <h5 className='mb-3 mt-3 title'>Trevi Fountain (2:45 - 3:15)</h5>
                                            <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Walk to the Spanish Steps, an iconic Roman landmark leading up to the Trinità dei Monti church. Relax and people-watch from the steps, enjoying the lively Piazza di Spagna. Explore the nearby high-end shops and cafés, concluding your tour with a blend of cultural and leisurely experiences.</p>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '60px' }}>Back to destination</p>
                                <h2 style={{ fontSize: '22px' }}>Additional infos</h2>
                                <div className="section">
                                    <h2 style={{ fontSize: '18px' }}>Physical Requirements</h2>
                                    <p>A moderate level of fitness is recommended. The tour includes walking and standing for extended periods (up to 3-4 hours at a time). Comfortable walking shoes are essential.</p>
                                </div>

                                <div className="section">
                                    <h2 style={{ fontSize: '18px' }}>Weather</h2>
                                    <p>Rome enjoys a Mediterranean climate:</p>
                                    <ul>
                                        <li>In summer, temperatures can exceed 90°F (32°C), so pack light, breathable clothing.</li>
                                        <li>Spring and fall are ideal, with pleasant, mild temperatures.</li>
                                        <li>In cooler months (October to March), please bring a light jacket.</li>
                                    </ul>
                                    <p>Don’t forget sunscreen, a hat, and a water bottle.</p>
                                </div>

                                <div className="section">
                                    <h2 style={{ fontSize: '18px' }}>Dress Code</h2>
                                    <p>Comfortable and respectful clothing is recommended. When visiting churches and religious sites (e.g., St. Peter’s Basilica), modest attire is required (no shorts or sleeveless tops). Comfortable shoes for walking are essential.</p>
                                </div>

                                <div className="section">
                                    <h2 style={{ fontSize: '18px' }}>Cancellation Policy</h2>
                                    <ul>
                                        <li>Full refund for cancellations made 30 days or more before departure.</li>
                                        <li>50% refund for cancellations made 15-29 days before departure.</li>
                                        <li>No refund for cancellations made less than 15 days before departure.</li>
                                    </ul>
                                </div>

                                <div class="section">
                                    <h2 style={{ fontSize: '18px' }}>How to Book</h2>
                                    <p>Book online through our website or call our customer service team at <strong>[phone number]</strong> for assistance.</p>
                                    <p>A 20% deposit is required to secure your reservation. The remaining balance is due 30 days before the tour starts.</p>
                                </div>

                                <div class="section">
                                    <h2 style={{ fontSize: '18px' }}>Customer Support</h2>
                                    <p>Our team is available 7 days a week to assist with any questions or concerns before, during, or after your tour. Please feel free to reach out via email at <strong>[email address]</strong> or by calling <strong>[phone number]</strong>.</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={'4'} xl={'4'} className={'mb-4 mb-xl-0'}>
                            <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                                <Card.Body className='p-4'>
                                    <p className='secondaryText mb-0' style={{fontSize:'18px',fontWeight:'bold'}}>From</p>
                                    <p className='secondaryText' style={{fontSize:'28px',fontWeight:'bold'}}>$200</p>
                                    <p className='secondaryText' style={{fontSize:'22px',fontWeight:'600'}}>Select Car Type</p>
                                    <p className='text-center'><img className='img-fluid' src={carimage} alt='icon' /></p>
                                    <Row className='gx-0 align-items-center justify-content-between mb-3'>
                                    <Col sm={'6'} className=''>
                                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={userIcon} alt='icon' /><p className='mb-0 fw-semibold' style={{fontSize:'14px'}}>2 Passanger</p></div>
                                    </Col>
                                    <Col sm={'6'} className=''>
                                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={breifIcon} alt='icon' /><p className='mb-0 fw-semibold' style={{fontSize:'14px'}}>2 Suitcase</p></div>
                                    </Col>
                                    </Row>
                                    {/*  */}
                                    <Row className='gx-0 align-items-center justify-content-between'>
                                    <Col sm={'6'} className=''>
                                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={meetgreetIcon} alt='icon' /><p className='mb-0 fw-semibold' style={{fontSize:'14px'}}>Meet & Greet</p></div>
                                    </Col>
                                    <Col sm={'6'} className=''>
                                        <div className='d-flex align-items-center'><img className='img-fluid me-2' src={timeIcon} alt='icon' /><p className='mb-0 fw-semibold' style={{fontSize:'14px'}}>30 min waiting time</p></div>
                                    </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className={'whiteBG mb-4'} style={{ boxShadow: 'none', borderRadius: '24px', border: 'none' }}>
                                <Card.Body className='p-4'>
                                    <h6 className={'secondaryText fw-bold mb-4'} style={{ fontSize: '26px' }}>Package Includes</h6>
                                    <ul className='list-unstyled'>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Hotel accommodation with breakfast.</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Professional English-speaking guide.</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Entrance fees to major attractions.</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Welcome dinner.</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Map of Rome and tour booklet.</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> International airfare.</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Personal expenses (shopping, additional meals).</li>
                                        <li style={{ fontSize: '14px', lineHeight: '32px' }}><img className='img-fluid me-2' src={checkicon} alt={'icon'} /> Accommodation upgrades.</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </section>
            {/*  */}
            <section className='contentCityTour color1BG'>
                <Col xxl={'10'} xl={'10'} className='col-11 mx-auto'>
                <div className='text-center pb-4 pb-lg-5'>
                    <h2 className='mb-4'>OTHER Packages ON ROME CITY TOUR</h2>
                    <Swiper {...optionsprojects} className='mt-5'>
                        <SwiperSlide>
                            <Card className='boxCard' style={{ cursor: 'pointer',border: 'none' }}>
                                <CardHeader className='mb-3 p-0'><img className='img-fluid helloImg' src={firstimage} alt='dfd' /></CardHeader>
                                <CardBody className='p-3'>
                                    <h5 className='title'>Rome Unveiled: A Classic Journey Through the Eternal City</h5>
                                    <p style={{textAlign:'left', fontSize: '14px', lineHeight: '16px'}}>Explore over a million flight options with our comprehensive search... </p>
                                    <Button variant={''} style={{height:'45px'}} type="button" className="loginbtn w-100">See Details</Button>
                                </CardBody>
                            </Card>
                        </SwiperSlide> 
                        <SwiperSlide>
                        <Card className='boxCard' style={{ cursor: 'pointer',border: 'none' }}>
                            <CardHeader className='mb-3 p-0'><img className='img-fluid helloImg' src={twoimage} alt='dfd' /></CardHeader>
                            <CardBody className='p-3'>
                                <h5 className='title'>Rome Unveiled: A Classic Journey Through the Eternal City</h5>
                                <p style={{textAlign:'left', fontSize: '14px', lineHeight: '16px'}}>Explore over a million flight options with our comprehensive search... </p>
                                <Button variant={''} style={{height:'45px'}} type="button" className="loginbtn w-100">See Details</Button>
                            </CardBody>
                        </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                        <Card className='boxCard' style={{ cursor: 'pointer',border: 'none' }}>
                            <CardHeader className='mb-3 p-0'><img className='img-fluid helloImg' src={thirdimage} alt='dfd' /></CardHeader>
                            <CardBody className='p-3'>
                                <h5 className='title'>Rome Unveiled: A Classic Journey Through the Eternal City</h5>
                                <p style={{textAlign:'left', fontSize: '14px', lineHeight: '16px'}}>Explore over a million flight options with our comprehensive search... </p>
                                <Button variant={''} style={{height:'45px'}} type="button" className="loginbtn w-100">See Details</Button>
                            </CardBody>
                        </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                        <Card className='boxCard' style={{ cursor: 'pointer',border: 'none' }}>
                            <CardHeader className='mb-3 p-0'><img className='img-fluid helloImg' src={firstimage} alt='dfd' /></CardHeader>
                            <CardBody className='p-3'>
                                <h5 className='title'>Rome Unveiled: A Classic Journey Through the Eternal City</h5>
                                <p style={{textAlign:'left', fontSize: '14px', lineHeight: '16px'}}>Explore over a million flight options with our comprehensive search... </p>
                                <Button variant={''} style={{height:'45px'}} type="button" className="loginbtn w-100">See Details</Button>
                            </CardBody>
                        </Card>
                        </SwiperSlide>
                    </Swiper>
                </div>
                </Col>
            </section>
            {/*  */}
        </>
    );
};

export default CitiTourDetail;
