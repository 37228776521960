import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import AOS from 'aos';
import 'swiper/css';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import config from '../config';
import Loading from '../components/Loading';
import { handleApiError } from '../utils/errorHandler';
// import component
import FlightBookingSearch from '../components/FlightBookingSearch.jsx';
// import image
import FlightBookingUser from '../images/flight-book-user.png';

const FlightBooking = () => {
  const [isLoading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    setLoading(true);
    const fetchdata = async () => {
    };
    fetchdata();
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  const GetCitiesByContinent = async (continent) => {
    try {
      if(continent === null || continent === ''){
          continent = 'popular'; 
      }
      const result = await axios.get(continent === 'popular' ? `${config.CitiesFilter(continent, 'true')}` : `${config.CitiesFilter('continent', continent)}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
        }
      });
      if (result?.data) {
        setCities(result.data);
      } else {
        setCities([]);
      }
    } catch (error) {
      handleApiError(error);
      setCities([]);
    } finally {
      setLoading(false); // reset loading state
    }
  }
  return (
    <main>
      <section className="banner flightbooking d-flex image_01 align-items-center justify-content-center"> 
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1 data-aos="zoom-in-up" data-aos-duration="1000">Catch Your Flight In a Snap - <span className=''>Book Now</span> </h1>
          {/* <p data-aos="zoom-in-up" data-aos-duration="1000" className='mb-4 text-white'>Arrive in style and comfort with our reliable airport transfer services, ensuring a smooth transition from terminal to destination. </p> */}
          {/* <Link  data-aos="zoom-in-up" data-aos-duration="1000" to='/' className="booknow d-inline-flex">Explore Now</Link> */}
        </Col>
      </section> 
      {/*  */}
      <FlightBookingSearch />
      {/*  */}
        <section className='howItWork' style={{ paddingBottom: '60px', paddingTop: '160px' }}>
            <Col xxl={'9'} xl={'10'} className='col-11 mx-auto bgImage'>
            <Row className={'align-items-center'}>
                <Col className={''} xxl={'6'} xl={'6'}>
                    <h5 className='text-start allTile mb-4'>fddfdfdf</h5>
                    <div className='description mb-4' style={{ textAlign: 'justify' }}>
                        dsfdfds dfdf dds fs f
                    </div>
                </Col>
                <Col xxl={'6'} xl={'6'} className={'mb-4 mb-lg-0 text-center'}>
                <div className='p-3' style={{ borderRadius: '8px' }}>
                    <img className='img-fluid w-100' style={{ minHeight: '430px', objectFit: 'cover', maxHeight: '400px', borderRadius: '8px', }} src={FlightBookingUser} alt={"flight-booking-user"} />
                </div>
                </Col>
            </Row>
            </Col>
        </section>
    </main>
  );
};

export default FlightBooking;