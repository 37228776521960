import React from 'react';
import { Card, CardBody, CardHeader } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import mapimg from '../images/mapimg.svg';
 
function ExperienceUL(props) {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/${props.slug}`);
  };
  return (
    <>

      <Card className='boxCard' onClick={handleCardClick} style={{ cursor: 'pointer' }} key={props.id}>
          <CardHeader className='mb-3'><img className='img-fluid helloImg' style={{ height: '252px', objectFit: 'cover', width: '100%' }} src={props.mainimg} alt={props.alttext} /></CardHeader>
          <CardBody className='p-0'>
            <p className='m-0 d-flex align-items-center'><img style={{ width: '10px' }} className='img-fluid me-2' src={mapimg} alt='img' /> {props.lcationtext}</p>
            <h5 className='title'>{props.title}</h5>
          </CardBody>
      </Card>

    </>
  )
}

export default ExperienceUL;