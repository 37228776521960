import React from 'react';

// import 
import {Card, Row, Col} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';

const FeaturedPostsUL = (props) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/blog/${props.slug}`);
  };
  return (
    <>
      <Card className='mb-4' onClick={handleCardClick}>
        <Row className={'align-items-center gx-0'}>
          <Col xxl={'5'} xl={'5'} lg={'5'} className={''}>
            <div className='cardImg'><img className='img-fluid' src={props.cardImg} alt='icon'/></div>
          </Col>
          <Col xxl={'7'} xl={'7'} lg={'7'} className={''}>
            <Card.Body>
              <small className='d-block mb-2'>{props.topText}</small>
              <Link onClick={handleCardClick} className={'title d-block mb-3'}>{props.title}</Link>
              <p>{props.date}</p>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default FeaturedPostsUL;