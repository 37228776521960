import React, { useEffect } from 'react';
import { Breadcrumb, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
// import images
import imageAgency_01 from "../images/imageAgency_01.png";
import imageAgency_02 from "../images/imageAgency_02.png";
import imageAgency_03 from "../images/imageAgency_03.png";
import imageAgency_04 from "../images/imageAgency_04.png";
import imageAgency_05 from "../images/imageAgency_05.png";
import imageAgency_06 from "../images/imageAgency_06.png";
import imageAgency_07 from "../images/imageAgency_07.png";
import imageAgency_08 from "../images/imageAgency_08.png";
const TravelAgencies = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return ( 
    <>
        <section className="banner travelbanner d-flex align-items-center justify-content-center image_01 mb-5" style={{ height: 'calc(100vh - 35vh)', borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1>Travel <span className=''>Agencies</span></h1>
          {/* <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Travel Agencies</Breadcrumb.Item>
          </Breadcrumb> */}
          <p className='text-white'>Elevate your travel experiences and ignite your wanderlust with Limowide's inspiring blog content.</p>
        </Col>
      </section>     
      {/*  */}
      <section className='info_wrap'>
        <Col xxl={'8'} xl={'9'} className='col-11 mx-auto'>
          <div className='text-center mb-4 pb-4 mb-lg-5 pb-lg-5'>
            <h2 className='mb-4'>Benefits of Partnering with Limowide for Travel Agencies</h2>
            <p className='pragraphText' style={{ fontSize: '18px', }}>Travel agencies play a crucial role in the tourism industry, and partnering with Limowide can enhance their service offerings significantly. Here are the key benefits of collaborating with us:</p>
          </div>
        </Col>
      </section>
      {/*  */}
      <section className='contentAgency mb-4 pb-4'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <Row className="align-items-center">
            <Col lg={'6'} className="mb-4 mb-lg-0">
              <div className='d-flex align-items-start position-relative mb-5'>
                <h4 className='text-center titleNumber'>01</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>B2B Account Management</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Establishing a B2B account with Limowide allows travel agencies to access exclusive rates and services tailored to their business needs. This partnership enables agencies to provide competitive pricing to their clients while maximizing their profit margins.</p>
                </div>
              </div>
              {/*  */}
              <div className='d-flex align-items-start position-relative mb-5'>
                <h4 className='text-center titleNumber'>02</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>Flexible Invoice Options</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Limowide offers flexible invoicing solutions that cater to the unique requirements of travel agencies. Agencies can choose from various invoicing formats, ensuring seamless financial management and transparency in transactions.</p>
                </div>
              </div>
              {/*  */}
              <div className='d-flex align-items-start position-relative mb-5'>
                <h4 className='text-center titleNumber'>03</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>Customer Vouchers</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Agencies can easily generate and share vouchers with their customers, enhancing the booking experience. These vouchers can be customized to include specific details, making them a valuable tool for promotions and special offers.</p>
                </div>
              </div>
              {/*  */}
              <div className='d-flex align-items-start position-relative mb-5 mb-lg-0'>
                <h4 className='text-center titleNumber'>04</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>Personal Interaction Support</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Our dedicated support team is available for personal interaction, ensuring quick assistance whenever needed. Travel agencies can rely on our knowledgeable staff to resolve issues promptly, enhancing their operational
                    efficiency.</p>
                </div>
              </div>
            </Col>
            <Col lg={'6'} className="text-center">
              <p className='m-0 d-lg-block d-none'><img className='img-fluid' src={imageAgency_01} alt='image' /></p>
              <p className='m-0 d-lg-none d-flex align-items-center'>
                <div><img className='img-fluid' src={imageAgency_02} alt='image' /></div>
                <div><img className='img-fluid' src={imageAgency_03} alt='image' /></div>
                <div><img className='img-fluid' src={imageAgency_04} alt='image' /></div>
              </p>
            </Col>
          </Row>
        </Col>
      </section>
      {/*  */}
      <section className='contentAgency'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <Row className="align-items-center">
            <Col lg={'6'} className="text-center order-1 order-lg-0">
              <p className='m-0 d-lg-block d-none'><img className='img-fluid' src={imageAgency_05} alt='image' /></p>
              <p className='m-0 d-lg-none d-flex align-items-center justify-content-between'>
                <div><img className='img-fluid' src={imageAgency_06} alt='image' /></div>
                <div><img className='img-fluid' src={imageAgency_07} alt='image' /></div>
              </p>
            </Col>
            <Col lg={'6'} className="mb-4 mb-lg-0">
              <div className='d-flex align-items-start position-relative mb-5'>
                <h4 className='text-center titleNumber'>05</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>Custom Requirement Quote</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Limowide allows travel agencies to request custom quotes based on specific client requirements. This feature enables agencies to cater to diverse client needs, from luxury travel to budget-friendly
                    options.</p>
                </div>
              </div>
              {/*  */}
              <div className='d-flex align-items-start position-relative mb-5'>
                <h4 className='text-center titleNumber'>06</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>Flexible Booking Amendments and Cancellations</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>We understand that travel plans can change. Limowide provides flexible booking amendment and cancellation policies, allowing agencies to accommodate their clients' needs without hassle. This flexibility fosters customer satisfaction and loyalty.</p>
                </div>
              </div>
              {/*  */}
              <div className='d-flex align-items-start position-relative mb-5'>
                <h4 className='text-center titleNumber'>07</h4>
                <div className='ms-lg-4 ms-3'>
                  <h5 className='mb-3 title'>Hourly Car Booking and City Tour Options</h5>
                  <p className='mb-0 pragraphText' style={{ fontSize: '18px', }}>Agencies can offer their clients the convenience of hourly car bookings and curated city tours. This flexibility allows travelers to explore destinations at their own pace, enhancing their overall experience.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </section>
      {/*  */}
      <section className='contentAgency py-5'>
        <Col xxl={'9'} xl={'10'} className='col-11 mx-auto'>
          <Row className="align-items-center">
            <Col lg={'6'}>
              <p className='pragraphText mb-4' style={{fontSize:'18px',}}>Partnering with Limowide empowers travel agencies to provide exceptional services to their clients. With our comprehensive benefits, agencies can enhance their offerings, streamline operations, and ultimately drive growth in their business.</p>
              <Link href='#' className='allView-btn mb-4 mb-md-0' style={{maxWidth:'236px',}}>Register as Partner</Link>
            </Col>
            <Col lg={'6'} className='text-center'><img className='img-fluid' src={imageAgency_08} alt='image' /></Col>
          </Row>
        </Col>
      </section>
    </>
  );
};

export default TravelAgencies;
