import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Row,Card, CardBody } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Loading from '../components/Loading';
import { handleApiError } from '../utils/errorHandler';
import ExperienceUL from '../components/ExperienceUL';
const Destinations = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [cities, setCities] = useState({});
  useEffect(() => {
    setLoading(true);
    const fetchdata = async () => {
      try {
        const result = await axios.get(`${config.Cities}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            'Access-Token': '4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585'
          }
        });
        if (result?.data) {
          setCities(result.data);
        } else {
          setCities([]);
        }
      } catch (error) {
        handleApiError(error);
        setCities([]);
      } finally {
        setLoading(false); // reset loading state
      }
    };
    fetchdata();
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <>
      <section className="banner d-flex align-items-center justify-content-center image_01" style={{ height: 'calc(100vh - 60vh)', borderRadius: '0px 0px 44px 44px' }}>
        <Col md={'9'} className='text-center col-11' style={{ zIndex: '1', position: "relative" }}>
          <h1><span className=''>Destinations</span></h1>
          <Breadcrumb className='mb-0'>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>Destinations</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </section>
      {/*  */}
      <section className='py-4 col-11 mx-auto'>
      {Object.entries(cities).map(([country, cities]) => (
        <div key={country}>
        <h4 style={{ color: 'rgb(250 224 105 / 71%)', fontSize:'48px', marginBottom:'15px', fontFamily: 'Prosto One'}}>{country}</h4>
        <Row className="alldestinations">
            {isLoading ? (
              <div className="flex justify-center items-center h-96">
                <Loading />
              </div>
            ) : (
              <>
                {cities.length > 0 ? (
                  cities.map((val, index) => {
                    return (
                      <Col xxl={'3'} xl={'3'} sm={'12'} className='mb-4' key={index}>
                        <Card className='boxCard' onClick={() => { navigate(`/${val.slug}`); }} style={{ cursor: 'pointer' }} key={val.id}>
                            <CardBody className='position-relative p-0'>
                              <img className='img-fluid helloImg' style={{ height: '165px', objectFit: 'fill', width: '100%' }} src={val.thumbnail_image} alt={'ddf'} />
                              {/* <p className='m-0 d-flex align-items-center'><img style={{ width: '10px' }} className='img-fluid me-2' src={mapimg} alt='img' /> {props.lcationtext}</p> */}
                              <h5 className='position-absolute title w-100' style={{ top: '30%', padding:'16px', textAlign: 'center', color: 'white', fontSize: '22px', zIndex:'1' }}>{val.city_name}</h5>
                            </CardBody>
                        </Card> 
                      </Col>
                    );
                  })
                ) : (
                  <div className="flex justify-center items-center h-96">
                    <p className="text-lg font-bold ">No Record Found!</p>
                  </div>
                )}
              </>
            )}
        </Row>
        </div>
      ))}
      </section>
    </>
  );
};

export default Destinations;
